/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function (props, _ref) {
  var $f7 = _ref.$f7,
      $update = _ref.$update;
  // Login screen demo data
  var username = '';
  var password = '';

  var updateUsername = function updateUsername(e) {
    username = e.target.value;
    $update();
  };

  var updatePassword = function updatePassword(e) {
    password = e.target.value;
    $update();
  };

  var alertLoginData = function alertLoginData() {
    $f7.dialog.alert('Username: ' + username + '<br/>Password: ' + password, function () {
      $f7.loginScreen.close();
    });
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">

    <!-- Left panel with cover effect-->
    <div class="panel panel-left panel-cover theme-dark panel-init">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title"><img class="logo" src="/static/logo.png" /><h2>CRM</h2></div>
            </div>
          </div>
          <div class="page-content">
            <div class="">
              <div class="list links-list">
                        <ul>
                          <li><a class="item-content item-link panel-close tab-link" href="/" data-route-tab-id="home">GWD Leads</a></li>
                          <!--<li><a class="item-content item-link panel-close tab-link" href="#view-melodyave">Melody Ave</a></li>-->
                          <li><a class="item-content item-link panel-close tab-link" href="/newlead">New Lead</a></li>
                          <!--<li><a class="item-content item-link panel-close tab-link" href="#view-proposal">New Proposal</a></li>-->
                          <li><a class="item-content item-link panel-close tab-link login-menu" href="/login">Login</a></li>
                          <li><a class="panel-close">Close Menu</a></li>
                        </ul>
                      </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right panel with reveal effect-->
    <div class="panel panel-right panel-reveal theme-dark">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Right Panel</div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">Right panel content goes here</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Views/Tabs container -->
    <div class="views tabs safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <div class="toolbar toolbar-bottom tabbar-labels">
        <div class="toolbar-inner">
          <a href="/" class="tab-link tab-link-active">
            <img src="/static/icons/gwd-g-icon.png"/>
            <!--<i class="icon f7-icons if-not-md">house_fill</i>
            <i class="icon material-icons if-md">home</i>-->
            <span class="tabbar-label">Leads</span>
          </a>
          <a href="/newlead" id="newlead" class="tab-link">
            <img src="/static/icons/plus-add.png"/>
            <!--<span class="tabbar-label">New Lead</span>-->
          </a>
          <!--<a href="#view-proposal" id="proposal" class="tab-link">
            <img src="/static/icons/proposal.png"/>
          </a>-->
        </div>
      </div>

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <div id="view-home" class="view view-main view-init tab tab-active" data-url="/">
        <!-- Home page will be loaded here dynamically from / route -->
      </div>

      <!-- Login View
      <div id="view-login" class="view view-init tab" data-name="login" data-url="/login">

      </div>


      <div id="view-newlead" class="view view-init tab" data-name="newlead" data-url="/newlead">

      </div>


      <div id="view-proposal" class="view view-init tab" data-name="proposal" data-url="/proposal">

      </div>
       -->
    </div>






    <!-- Popup -->
    <div class="popup" id="my-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Popup</div>
              <div class="right">
                <a href="#" class="link popup-close">Close</a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
              <p>Popup content goes here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Login Screen -->
    <div class="login-screen" id="my-login-screen">
      <div class="view">
        <div class="page">
          <div class="page-content login-screen-content">
            <div class="login-screen-title">Login</div>
            <div class="list">
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">Username</div>
                    <div class="item-input-wrap">
                      <input type="text" name="username" placeholder="Your username" value="${username}" @input="${updateUsername}"/>

                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-title item-label">Password</div>
                    <div class="item-input-wrap">
                      <input type="password" name="password" placeholder="Your password" value="${password}" @input="${updatePassword}"/>

                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="list">
              <ul>
                <li>
                  <a href="#" class="item-link list-button login-button" @click="${alertLoginData}">Sign In</a>

                </li>
              </ul>
              <div class="block-footer">Some text about login information.<br/>Click "Sign In" to close Login Screen</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
`
    }
    ;
});