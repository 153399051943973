import $ from 'dom7';
import Framework7, { getDevice } from 'framework7/bundle';

// Import F7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';
// Import Store
import store from './store.js';

// Import main app component
import App from '../app.f7.html';

var device = getDevice();
var app = new Framework7({
  name: 'GWD CRM', // App name
  theme: 'auto', // Automatic theme detection
  el: '#app', // App root element
  component: App, // App main component
  id: 'com.graphicwebdesigninc.sales', // App bundle ID
  // App store
  store: store,
  // App routes
  routes: routes,
  //panel: {
  //    swipe: 'left',
  //},
  view: {
    stackPages: true, //empilhar paginas para mostrar no mesmo arquivo HTML    
    browserHistory: true, //voltar com o botão físico
    browserHistoryRoot: "https://sales.graphicwebdesigninc.com",
    browserHistorySeparator:"/",
    animateWithJS: true,
  },
  browserHistory : true,
  touch: {
    // Enable fast clicks
    fastClicks: true,
  },
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.cordova && !device.electron,
    scrollIntoViewCentered: device.cordova && !device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
    },
  },
});



var environment = 'sandbox';
var appName = 'gwd_sales';
var loggedIn = false;
var domain = 'https://secure.graphicwebdesigninc.com/';
var domainNoSlash = 'https://secure.graphicwebdesigninc.com';
var APIdomain = 'https://secure.graphicwebdesigninc.com/';





$('#view-home').on('tab:show', function (view) {
  console.log('view-home show event');
  localStorage.setItem('page_session','home');
  //homeView.router.navigate('/', {reloadAll: true} );
  get_crm_sales_gwd_actions();
});
$('#view-login').on('tab:show', function (view) {
  console.log('view-login show event');
  localStorage.setItem('page_session','login');
  //loginView.router.navigate('/login/', {reloadAll: true} );
});
$('#view-melodyave').on('tab:show', function (view) {
  console.log('view-melodyave show event');
  localStorage.setItem('page_session','melodyave');
  //melodyaveView.router.navigate('/melodyave/', {reloadAll: true} );
});
$('#view-newlead').on('tab:show', function (view) {
  console.log('view-newlead show event');
  localStorage.setItem('page_session','newlead');
  //newleadView.router.navigate('/newlead/', {reloadAll: true} );
});





function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var days = ['Sun','Mon','Tue','Wed','Thur','Fri','Sat'];
  var day = days[a.getDay()];
  var hour = a.getHours();
  var amPm = 'AM';
  // Convert From Military Time and Set AM or PM
  if(hour > 12){amPm = 'PM';hour = hour-12;}
  var min = a.getMinutes();
  if(min < 10){min = '0'+min;}
//hi 
  var sec = a.getSeconds();
  var time = day + ' ' + date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ' ' +amPm;
  return time;
}


function jsonEscape(str)  {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
}

function convertLineBreaks(str2) {
  return str2.replace(new RegExp("\\\\n", "g"), "<br />");
}

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

function header_updated_versioninfo(headerInfo) {

  headerInfo = '&#169 Graphic Web Design, Inc. '+new Date().getFullYear()+
  '<br />Updated: '+new Date().toLocaleString("en-US", {timeZone: "America/New_York"})+
  '<br />'+app.name+' v'+app.version;
  

  return headerInfo;
}





function new_document_id() {

  // Reach out to the server to get the number of documents created today 
    // Get Data from server
    var getTodaysDocumentsdataurl = APIdomain+'api/1.0/todays-documents.json';

    app.preloader.show();

    app.request({
      url: getTodaysDocumentsdataurl,
      method: 'GET',
      dataType:'json',
      headers: {
        //"X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json"
      },
      //data: taxonomyData,
      success: function (data, status, xhr) {

          if (data.length != 0){

            var documentLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.charAt(data.length);

            console.log('There are '+data.length+' documents generated today. Next letter = '+documentLetter);

          } else {
            
            var documentLetter = 'A';

            console.log('There are '+data.length+' documents generated today. Next letter = '+documentLetter);
       
          }
          app.preloader.hide();

          var todaysDate = new Date().toISOString().slice(0,10).replace(/-/g, "");

          console.log(todaysDate);
          console.log(documentLetter);

          var combinedID = todaysDate.toString()+documentLetter;

          console.log(combinedID);


                    // Project Scope ID
          $('.project-scope-id').html('Project ID: '+combinedID);
          //return combinedID;


      },
      error: function (xhr,status) {
        // Error
        //console.log(JSON.parse(xhr.responseText));
        console.log( "post taxonomy error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Document ID Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('post doc id - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('post doc id - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('post doc id - 404');
        },
        403: function (xhr) {
          console.log('post doc id - 403');
        },
        200: function (xhr) {
          console.log('post doc id - 200');
        }
      }
    });


}






function set_color_code(status_text,status_bg_color_code) {

  switch (status_text) {
    case 'new' :
      status_bg_color_code = 'new';
    break;
    case 'contacted' :
      status_bg_color_code = 'contacted';
    break;
    case 'appointment' :
      status_bg_color_code = 'appointment';
    break;
    case 'no_response' :
      status_bg_color_code = 'no_response';
    break;
    case 'discussion' :
      status_bg_color_code = 'discussion';
    break;
    case 'proposal_approval_pending' :
      status_bg_color_code = 'proposal_approval_pending';
    break;
    case 'payment_pending' :
      status_bg_color_code = 'payment_pending';
    break;
    case 'quoted' :
      status_bg_color_code = 'quoted';
    break;
    case 'negotiating' :
      status_bg_color_code = 'negotiating';
    break;
    case 'closed' :
      status_bg_color_code = 'closed';
    break;
    case 'cold' :
      status_bg_color_code = 'cold';
    break;
    case 'spam' :
      status_bg_color_code = 'spam';
    break;
    case 'test' :
      status_bg_color_code = 'test';
    break;
    case 'support' :
      status_bg_color_code = 'support';
    break;
    case 'duplicate' :
      status_bg_color_code = 'duplicate';
    break;
    case 'personal' :
      status_bg_color_code = 'personal';
    break;


  }

  return status_bg_color_code;
}

function check_session(uid,access) {

  if(localStorage.getItem('uid') == '') {
    $(".page-current .debug").html('');
    $('.page-current .debug').append('<span class="success-message">Login to Continue</span>');
  } else {
    uid = localStorage.getItem('uid');
  }

  var sessionCheckUrl = APIdomain+'api/1.0/user/'+uid+'.json';


    app.request({
      url: sessionCheckUrl,
      method: 'GET',
      async:false,
      dataType:'json',
      headers: {
        "X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json",
        "Authorization":"Basic  "+localStorage.getItem('auth_cred')
      },
      success: function (data, status, xhr) {

          console.log("check session data success");
          //console.log("register.done data = "+data);

          $(".page-current .debug").html('');
          $('.page-current .debug').append('<span class="success-message">Session Validated</span>');
         
          $('.login-menu').text('Logout');
          $('.login-menu').addClass('logout');

          $('.logout').on('click',function(){
            logout_user(localStorage.getItem('username'));
          });

          app.preloader.hide();
          loggedIn = true;
          

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        console.log( "check session error" );
        loggedIn = false;
        app.views.main.router.navigate('/login');
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('Please Login');
        //$(".page-current .debug").append('<span class="error-message">Session Expired, Please Login<br /></div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('check session - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('check session - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('check session - 404');
          $(".page-current .debug").append('<span class="error-message">Session Expired, Please Login<br /></div>');
          localStorage.setItem('uid','0');
          
        },
        403: function (xhr) {
          console.log('check session - 403');
          $(".page-current .debug").append('<span class="error-message">Session Expired, Please Login<br /></div>');
          localStorage.setItem('uid','0');
          
        },
        401: function (xhr) {
          console.log('check session - 401');
          $(".page-current .debug").append('<span class="error-message">Session Expired, Please Login<br /></div>');
          localStorage.setItem('uid','0');
          
        },
        200: function (xhr) {
          console.log('check session - 200');
        }
      }
    });

}



function get_crm_sales_melodyave_actions() {
  // Get Data from server
  var getNodedataurl = APIdomain+'api/1.0/crm-leads.json';

  app.preloader.show();


    app.request.json(getNodedataurl, { foo:'bar', id: 5 }, function (data) {


        //console.log(data);

        var body = '';
        var section =  '';
        var phone = '';
        var cleaned_phone = '';

        for (var i = 0; i < data.length; i++) {

          section = '';

          var status_bg_color_code = '';
          status_bg_color_code = set_color_code(data[i].status_text,status_bg_color_code);

          //console.log(data[i]);
          if (data[i].business_name_lead == 'Melody Ave' ||
            data[i].business_name_lead == 'melodyave') {

            phone = data[i].phone_number;
            cleaned_phone = (""+phone).replace('+1','');

            section = '<tr class="'+data[i].status_text+'"><td><a target="_system" class="external phone-number" href="tel:'+cleaned_phone+'">'+formatPhoneNumber(data[i].phone_number)+'</a>'+
            '<span class="name">'+data[i].name+'</span>'+
            '<span class="date-method">'+timeConverter(data[i].posted_date)+' - by '+data[i].method_of_contact+'</span>'+
            '<span>'+data[i].phone_menu_option+'</span>'+
            '<span class="status"><span class="inner '+status_bg_color_code+'">'+data[i].status_text.replace(/_/g,' ')+'</span></span>'+
            '</td><td class="next-page"><a href="/crm?nid='+data[i].nid+'"><i class="f7-icons icon material-icons color-white">arrow_right</i></a></td></tr>';

          }
          
          body = body+section;

        }

    
        $('.inner-wrapper .melodyave-leads-content-section table').html(body);



        // External Links
        $('a[target="_system"]').on('click', function (e) {
          e.preventDefault();
          var url = this.href;
          //window.open(url,"_system");

        console.log(url);

        // Play an audio file with options (all options optional)
        var options = {
          bgColor: "#f68a1f",
          //bgImage: "<SWEET_BACKGROUND_IMAGE>",
          //bgImageScale: "fit", // other valid values: "stretch", "aspectStretch"
          initFullscreen: true, // true is default. iOS only.
          keepAwake: false, // prevents device from sleeping. true is default. Android only.
          successCallback: function() {
            console.log("Player closed without error.");
          },
          errorCallback: function(errMsg) {
            console.log("Error! " + errMsg);
          }
        };
        window.plugins.streamingMedia.playAudio(url, options);



        });
        $('a[target="_blank"]').on('click', function (e) {
          e.preventDefault();
          var url = this.href;
          window.open(url,"_system");
        });

        $(document).on('click', 'a[target="_system"],a[target="_blank"]', function (e) {
                e.preventDefault();
                var url = this.href;
                //window.open(url,"_system");                    
        });

        $('a[target="_system"]').addClass('external-processed-node-content');
        $('a[target="_blank"]').addClass('external-processed-node-content');

        $('.melodyave-leads-updated-date').html(header_updated_versioninfo());


        //console.log(body3);
        app.preloader.hide();
  });

}






function get_crm_sales_gwd_actions() {

  check_session();

  console.log('loggedIn = '+loggedIn);

  //var iab = cordova.InAppBrowser;

  if (loggedIn == true) {

    // Get Data from server
    var getNodedataurl = APIdomain+'api/1.0/crm-leads.json';

    app.preloader.show();


      app.request.json(getNodedataurl, { foo:'bar', id: 5 }, function (data) {


          //console.log(data);

          var body = '';
          var section =  '';
          var phone = '';
          var cleaned_phone = '';
          var hideInitially = '';
          var daysAgoCreated = 0;
          var daysAgoCreatedClass = '';
          var daysAgoUpdated = 0;
          var daysAgoUpdatedClass = '';
          var unixNow = new Date().getTime() / 1000;
          var callRecordingURL = '';

          for (var i = 0; i < data.length; i++) {

            section = '';
            hideInitially = '';
            daysAgoCreated = 0;
            daysAgoCreatedClass = '';
            daysAgoUpdated = 0;
            daysAgoUpdatedClass = '';
            callRecordingURL = '';

            var status_bg_color_code = '';
            status_bg_color_code = set_color_code(data[i].status_text,status_bg_color_code);

            //console.log(data[i]);
            if (data[i].business_name_lead == 'Graphic Web Design, Inc.' ||
            data[i].business_name_lead == 'graphicwebdesigninc') {

              phone = data[i].phone_number;
              cleaned_phone = (""+phone).replace('+1','');

              // Hide all OTHER statuses initally
              if (data[i].status_text == '') {data[i].status_text = 'not_set';}


              if (data[i].status_text == 'cold' || data[i].status_text == 'test' || data[i].status_text == 'personal' || data[i].status_text == 'spam' || data[i].status_text == 'support' || data[i].status_text == 'duplicate') {
                hideInitially = ' hide';
              }

              if (data[i].status_text == 'not_set') {hideInitially = ' ';}
            
              //console.log(data[i].status_text+' | '+hideInitially);

              //console.log(unixNow+' unixNow');


              // Find out score for how many ~days ago this was created
              daysAgoCreated = ((unixNow - data[i].posted_date)/1000/60).toFixed(0);
              //console.log(daysAgoCreated.toFixed(0)+' days ago');
              if (daysAgoCreated > 15) {daysAgoCreatedClass = 'ugly';} else {daysAgoCreatedClass = 'good';}
              if (daysAgoCreated < 10) {daysAgoCreatedClass = 'great';}

              // Find out how many days ago this was updated
              daysAgoUpdated = ((unixNow - data[i].updated_date)/1000/60).toFixed(0);
              if (daysAgoUpdated > 4) {daysAgoUpdatedClass = 'ugly';} else {daysAgoUpdatedClass = 'good';}
              if (daysAgoUpdated < 2) {daysAgoUpdatedClass = 'great';}

              // Sales Agent
              var sales_agent_img = '';
              if (data[i].sales_agent == 'steven') {
                sales_agent_img = '<img src="https://secure.graphicwebdesigninc.com/sites/default/files/pictures/picture-1-1604043543.jpg">';
              }
              if (data[i].sales_agent == 'tony') {
                sales_agent_img = '<img src="https://secure.graphicwebdesigninc.com/sites/default/files/styles/thumbnail/public/pictures/picture-121-1604042945.jpg">';
              }

              // Call Recording
              if (data[i].call_recording_url.includes('calltracking')) {
                //console.log(data[i].call_recording_url);
                callRecordingURL = '<span class="call-recording-wrap"><a class="call-recording" href="/call?url='+data[i].call_recording_url+'"><i class="icon material-icons color-white">speaker_2_fill</i></a></span>';
              }

              section = '<tr class="'+data[i].status_text+hideInitially+' '+data[i].sales_agent+'"><td><h3><a target="_system" class="external" href="tel:'+cleaned_phone+'">'+formatPhoneNumber(data[i].phone_number)+'</a></h3>'+
              '<span class="name">'+data[i].name+'</span>'+
              '<span class="date-method">'+timeConverter(data[i].posted_date)+' - by '+data[i].method_of_contact+'</span>'+
              '<span>'+data[i].phone_menu_option+'</span>'+
              '<div class="status-wrap"><span class="created-aging"><span class="inner '+daysAgoCreatedClass+'">'+daysAgoCreated+'</span></span><span class="updated-aging"><span class="inner '+daysAgoUpdatedClass+'">'+daysAgoUpdated+'</span></span><span class="status"><span class="inner '+status_bg_color_code+'">'+data[i].status_text.replace(/_/g,' ')+'</span></span><span class="salesagent-avatar"><span class="inner">'+sales_agent_img+'</span></span>'+callRecordingURL+'</div>'+
              '</td><td class="next-page"><a href="/crm?nid='+data[i].nid+'"><i class="f7-icons icon material-icons color-white">arrow_right</i></a></td></tr>';


              

            

            }
            
            body = body+section;

          }

      
          $('.inner-wrapper .gwd-leads-content-section table').html(body);



          // External Links
          $('a[target="_system"]').on('click', function (e) {
            e.preventDefault();
            var url = this.href;
            window.open(url,"_system");
          });
          $('a[target="_blank"]').on('click', function (e) {
            e.preventDefault();
            var url = this.href;
            window.open(url,"_system");
          });



          // External Links
          $('a[target="_system"]').on('click', function (e) {
            e.preventDefault();
            var url = this.href;
            //window.open(url,"_system");

          console.log(url);

          // Play an audio file with options (all options optional)
          var options = {
            bgColor: "#f68a1f",
            //bgImage: "<SWEET_BACKGROUND_IMAGE>",
            //bgImageScale: "fit", // other valid values: "stretch", "aspectStretch"
            initFullscreen: true, // true is default. iOS only.
            keepAwake: false, // prevents device from sleeping. true is default. Android only.
            successCallback: function() {
              console.log("Player closed without error.");
            },
            errorCallback: function(errMsg) {
              console.log("Error! " + errMsg);
            }
          };
          window.plugins.streamingMedia.playAudio(url, options);



          });

          $(document).on('click', 'a[target="_system"],a[target="_blank"]', function (e) {
                  e.preventDefault();
                  var url = this.href;
                  //window.open(url,"_system");                    
          });

          $('a[target="_system"]').addClass('external-processed-node-content');
          $('a[target="_blank"]').addClass('external-processed-node-content');


          $('.gwd-leads-updated-date').html(header_updated_versioninfo());


          

          //console.log(body3);
          app.preloader.hide();
    });



    // Filter actions


    // Sales Agent Tony
    $('#filters .checkbox-wrap .tony').on('click',function() {

      if ($('#filters .checkbox-wrap .tony')[0].checked == true) {
        console.log('tony was checked');
        $('#filters .checkbox-wrap input.new').prop("checked", false);
        $('#filters .checkbox-wrap input.contacted').prop("checked", false);
        $('#filters .checkbox-wrap input.quoted').prop("checked", false);
        $('#filters .checkbox-wrap input.steven').prop("checked", false);
        $('#filters .checkbox-wrap input.other').prop("checked", false);
        $('#gwd-leads tr').addClass('hide');
        $('#gwd-leads tr.tony').removeClass('hide');
      } else {
        //console.log('it was unchecked');
        $('#gwd-leads tr').addClass('hide');
      }

    });

    // Sales Agent Steven
    $('#filters .checkbox-wrap .steven').on('click',function() {

      if ($('#filters .checkbox-wrap .steven')[0].checked == true) {
        console.log('steven was checked');
        $('#filters .checkbox-wrap input.new').prop("checked", false);
        $('#filters .checkbox-wrap input.contacted').prop("checked", false);
        $('#filters .checkbox-wrap input.quoted').prop("checked", false);
        $('#filters .checkbox-wrap input.tony').prop("checked", false);
        $('#filters .checkbox-wrap input.other').prop("checked", false);
        $('#gwd-leads tr').addClass('hide');
        $('#gwd-leads tr.steven').removeClass('hide');
      } else {
        //console.log('it was unchecked');
        $('#gwd-leads tr').addClass('hide');
      }

    });


    // Other statuses
    $('#filters .checkbox-wrap .other').on('click',function() {

      if ($('#filters .checkbox-wrap .other')[0].checked == true) {
        //console.log('it was checked');
        $('#filters .checkbox-wrap input.tony').prop("checked", false);
        $('#filters .checkbox-wrap input.steven').prop("checked", false);
        $('#gwd-leads tr.cold').removeClass('hide');
        $('#gwd-leads tr.spam').removeClass('hide');
        $('#gwd-leads tr.test').removeClass('hide');
        $('#gwd-leads tr.support').removeClass('hide');
        $('#gwd-leads tr.duplicate').removeClass('hide');
        $('#gwd-leads tr.personal').removeClass('hide');
      } else {
        //console.log('it was unchecked');
        $('#gwd-leads tr.cold').addClass('hide');
        $('#gwd-leads tr.spam').addClass('hide');
        $('#gwd-leads tr.test').addClass('hide');
        $('#gwd-leads tr.support').addClass('hide');
        $('#gwd-leads tr.duplicate').addClass('hide');
        $('#gwd-leads tr.personal').addClass('hide');
      }

    });

    // New statuses
    $('#filters .checkbox-wrap .new').on('click',function() {

      if ($('#filters .checkbox-wrap .new')[0].checked == true) {
        //console.log('it was checked');
        $('#filters .checkbox-wrap input.tony').prop("checked", false);
        $('#filters .checkbox-wrap input.steven').prop("checked", false);
        $('#gwd-leads tr.new').removeClass('hide');
      } else {
        $('#gwd-leads tr.new').addClass('hide');
      }

    });

    // Follow Up statuses
    $('#filters .checkbox-wrap .contacted').on('click',function() {

      if ($('#filters .checkbox-wrap .contacted')[0].checked == true) {
        //console.log('it was checked');
        $('#filters .checkbox-wrap input.tony').prop("checked", false);
        $('#filters .checkbox-wrap input.steven').prop("checked", false);
        $('#gwd-leads tr.contacted').removeClass('hide');
        $('#gwd-leads tr.appointment').removeClass('hide');
        $('#gwd-leads tr.no_response').removeClass('hide');
        $('#gwd-leads tr.discussion').removeClass('hide');
      } else {
        //console.log('it was unchecked');
        $('#gwd-leads tr.contacted').addClass('hide');
        $('#gwd-leads tr.appointment').addClass('hide');
        $('#gwd-leads tr.no_response').addClass('hide');
        $('#gwd-leads tr.discussion').addClass('hide');
      }

    });

    // Quoted statuses
    $('#filters .checkbox-wrap .quoted').on('click',function() {

      if ($('#filters .checkbox-wrap .quoted')[0].checked == true) {
        //console.log('it was checked');
        $('#filters .checkbox-wrap input.tony').prop("checked", false);
        $('#filters .checkbox-wrap input.steven').prop("checked", false);
        $('#gwd-leads tr.quoted').removeClass('hide');
        $('#gwd-leads tr.negotiating').removeClass('hide');
        $('#gwd-leads tr.closed').removeClass('hide');
      } else {
        //console.log('it was unchecked');
        $('#gwd-leads tr.quoted').addClass('hide');
        $('#gwd-leads tr.negotiating').addClass('hide');
        $('#gwd-leads tr.closed').addClass('hide');
      }

    });

  }
  app.preloader.hide();

}

function call_actions(url) {

    // Set Data
    $('.call-content-section source').attr('src',url);

}



function crm_actions(nid) {
  
  // Set the proposal creation link
  $('#crm-4 .start-proposal').attr('href','/proposal?nid='+nid);

  // Get Lead Data from server
  var getNodeUrl = APIdomain+'api/1.0/node/'+nid+'.json';


  // Update Generate Proposal Button
  //$('.start-proposal').attr('href','/proposal?nid='+nid);

  app.preloader.show();

    app.request.json(getNodeUrl, { foo:'bar', id: 5 }, function (data) {


        //console.log(data);
        console.log('setting crm form');

            //data[i].name
            //data[i].posted_date
            //data[i].method_of_contact
            //data[i].phone_menu_option
            //data[i].nid


        // Set Data
        $('.crm-content-section .fullname').val(data.title);
        if(data.field_business_name.und){$('.crm-content-section .businessname').val(data.field_business_name.und[0].value);}
        $('.crm-content-section .created').text('Created: '+timeConverter(data.created));
        if(data.field_phone_number.und){$('.crm-content-section .phone').val(data.field_phone_number.und[0].value);}
        if(data.field_email.und){$('.crm-content-section .email').val(data.field_email.und[0].value);}
        if(data.field_domain_name_text.und){$('.crm-content-section .domain').val(data.field_domain_name_text.und[0].value);}
        if(data.field_sales_agent_text.und){$('.crm-content-section .sales-agent').val(data.field_sales_agent_text.und[0].value);}
        if(data.field_status_text.und){$('.crm-content-section .status').val(data.field_status_text.und[0].value);}
        if(data.field_existing_website.und){$('.crm-content-section .existing-website').val(data.field_existing_website.und[0].value);}
        if(data.field_logo.und){$('.crm-content-section .logo').val(data.field_logo.und[0].value);}
        if(data.field_lead_for_business_text.und){$('.crm-content-section .business_lead_name').val(data.field_lead_for_business_text.und[0].value);}
        if(data.field_method_of_contact.und){$('.crm-content-section .method_of_contact').val(data.field_method_of_contact.und[0].value);}
        if(data.field_web_design_example_domain.und){$('.crm-content-section .reference-website-1').val(data.field_web_design_example_domain.und[0].value);}
        if(data.field_web_design_example_domain2.und){$('.crm-content-section .reference-website-2').val(data.field_web_design_example_domain2.und[0].value);}
        if(data.field_web_design_example_domain3.und){$('.crm-content-section .reference-website-3').val(data.field_web_design_example_domain3.und[0].value);}
        if(data.field_phone_menu_option.und){$('.crm-content-section .phone-menu-option').val(data.field_phone_menu_option.und[0].value);}
        if(data.field_notes_sales_notes.und){$('.crm-content-section .sales-notes-log').html(convertLineBreaks(decodeURI(data.field_notes_sales_notes.und[0].value)));}
        
        // Address
        if(data.field_address.und){
          $('.crm-content-section .businessaddress').val(data.field_address.und[0].street);
          $('.crm-content-section .businesscity').val(data.field_address.und[0].city);
          $('.crm-content-section select.state').val(data.field_address.und[0].province);
          $('.crm-content-section .businesszip').val(data.field_address.und[0].postal_code);
        }

        // Call Recording
        if(data.field_call_recording_url.und){
          var callRecordingURL = data.field_call_recording_url.und[0].value;
          console.log(callRecordingURL);

          if (callRecordingURL.includes('calltracking')) {
            //console.log(data[i].call_recording_url);
            callRecordingURL = '<a class="call-recording" href="/call?url='+callRecordingURL+'"><i class="icon material-icons color-white">speaker_2_fill</i></a>';
            $('.crm-content-section .call-recording-wrap').removeClass('hide');
            $('.crm-content-section .call-recording-wrap').html(callRecordingURL);
          }
        } else {
            $('.crm-content-section .call-recording-wrap').addClass('hide');
        }

        // Revision Date
        $('.crm-content-section .last-revised').text('Updated: '+timeConverter(data.revision_timestamp));

        // Header Info
        $('.crm-leads-updated-date').html(header_updated_versioninfo());

        app.preloader.hide();
  });

  $('#crm-4 .submit').on('click',function(){

    var salesNotes = '<h6>'+new Date().toLocaleString("en-US", {timeZone: "America/New_York"})+'</h6>'+'<div class="single-sales-note">'+$('.page-current #crm-form .notes').val()+'</div>'+$('.sales-notes-log').html();

    update_lead(nid,encodeURI(salesNotes));

  });

  $('#crm-4 .start-proposal').on('click',function(){



  });

}


// Get Terms

function get_terms(taxonomy_id) {

  check_session();

  var taxonomyData = '{"vid":"'+taxonomy_id+'"}';

  console.log('loggedIn = '+loggedIn);

  //var iab = cordova.InAppBrowser;

  if (loggedIn == true) {

    // Get Data from server
    var getTermsTreedataurl = APIdomain+'api/1.0/taxonomy_vocabulary/getTree.json';

    app.preloader.show();

    app.request({
      url: getTermsTreedataurl,
      method: 'POST',
      dataType:'json',
      headers: {
        //"X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json"
      },
      data: taxonomyData,
      success: function (data, status, xhr) {


          //console.log(data);

          var list = '<option value="default">Select Industry</option><option value="other">Other</option>';
          var listItem = '';
          var section =  '';

          for (var i = 0; i < data.length; i++) {


            //console.log('this term = '+data[i].name);

            listItem = '<option data-tid="'+data[i].tid+'" value="'+data[i].name.toLowerCase()+'">'+data[i].name+'</option>';

             
            // Set listItem and list item value
            
            
            list = list+listItem;

          }

      
          $('.inner-wrapper .proposal-content-section .industry').html(list);


          //console.log(body3);
          app.preloader.hide();


      },
      error: function (xhr,status) {
        // Error
        //console.log(JSON.parse(xhr.responseText));
        console.log( "post taxonomy error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Industry Data Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('post taxonomy - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('post taxonomy - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('post taxonomy - 404');
        },
        403: function (xhr) {
          console.log('post taxonomy - 403');
        },
        200: function (xhr) {
          console.log('post taxonomy - 200');
        }
      }
    });


  }
  app.preloader.hide();

}


// Closest 50 dollars
function roundnum(num){
    return Math.round(num / 50)*50;
}


// Set the payment milestone line items
function set_payment_amounts(total,deposit_payment,remaining_payment,design_payment,launch_payment,second_total) {
  // Set payment section 
  console.log('total = '+total);

  deposit_payment = roundnum(total * 0.4);

  console.log('deposit = '+deposit_payment);
  $('#proposal-5 .payments1cost').val(deposit_payment);

  remaining_payment = total - deposit_payment;

  design_payment = remaining_payment/2;
  console.log('design = '+design_payment);
  $('#proposal-5 .payments2cost').val(design_payment);

  launch_payment = total-deposit_payment-design_payment;
  console.log('launch = '+launch_payment);
  $('#proposal-5 .payments3cost').val(launch_payment);

  second_total = deposit_payment+design_payment+launch_payment;

  console.log('added = '+second_total);


  $(".page-current .debug").html('');
  $(".page-current .debug").append('<span class="success-message">Payment Amounts Set - Total $'+second_total+'</div>');
        
  check_math(second_total);

}

function check_math(second_total) {


  if(parseInt($('#proposal-5 .line1cost').val())+
    parseInt($('#proposal-5 .line2cost').val())+
    parseInt($('#proposal-5 .line3cost').val())+
    parseInt($('#proposal-5 .line4cost').val())+
    parseInt($('#proposal-5 .line5cost').val()) != parseInt(second_total)) {

    $(".page-current .debug").html('');
    $(".page-current .debug").append('<span class="error-message">Your line items dont add up.</div>');
       

  } else {
      $(".page-current .debug").html('');
      $(".page-current .debug").append('<span class="success-message">Your line items add up. Nice work Einstein.</div>');
      
  }
 
}


// Get Term Details

function get_term_details(term_id) {

  check_session();

  ////var termData = '{"tid":"'+taxonomy_id+'"}';

  console.log('loggedIn = '+loggedIn);

  //var iab = cordova.InAppBrowser;

  if (loggedIn == true) {

    // Get Data from server
    var getTermDetailsdataurl = APIdomain+'api/1.0/taxonomy_term/'+term_id+'.json';

    app.preloader.show();

    app.request({
      url: getTermDetailsdataurl,
      method: 'GET',
      dataType:'json',
      headers: {
        //"X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json"
      },
      //data: termData,
      success: function (data, status, xhr) {


          //console.log(data);

          // Brand Name
          //if ($('.proposal-content-section span.businessname').html() !== 'Brand: ') {$('.proposal-content-section input.brand').val($('.proposal-content-section span.businessname').html().replace('Brand: ',''))}

          // Objective
          if(data.field_objective.und){$('#proposal-5 textarea.objective').val(data.field_objective.und[0].value);}
          
          // Example Websites
          if(data.field_example_domain_1.und){$('#proposal-5 .example-domain-one').val(data.field_example_domain_1.und[0].value);}
          if(data.field_example_domain_2.und){$('#proposal-5 .example-domain-two').val(data.field_example_domain_2.und[0].value);}
          if(data.field_example_domain_3.und){$('#proposal-5 .example-domain-three').val(data.field_example_domain_3.und[0].value);}


          // Scope
          if(data.field_page_3_scope_c_user_interf.und){$('#proposal-5 .webpages').val(data.field_page_3_scope_c_user_interf.und[0].value);}
          if(data.field_page_3_scope_d_features.und){$('#proposal-5 .features').val(data.field_page_3_scope_d_features.und[0].value);}
          if(data.field_page_3_scope_e_timeline.und){$('#proposal-5 .timeline').val(data.field_page_3_scope_e_timeline.und[0].value);}

          var total = 0;
          // Line Items

          console.log(data.field_line_item_1_cost.und[0].value);

          if(data.field_line_item_1_cost.und){$('#proposal-5 .line1cost').val(data.field_line_item_1_cost.und[0].value);}
          if(data.field_line_item_2_cost.und){
            $('#proposal-5 .line2cost').val(data.field_line_item_2_cost.und[0].value);

            // Total is line 6
            total = parseInt(data.field_line_item_1_cost.und[0].value)+parseInt(data.field_line_item_2_cost.und[0].value);
          
            $('#proposal-5 .line6cost').val(total);

            // Set Deposit Amounts
            set_payment_amounts(total);
          }


          //console.log(body3);
          app.preloader.hide();


      },
      error: function (xhr,status) {
        // Error
        //console.log(JSON.parse(xhr.responseText));
        console.log( "post taxonomy error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Industry Data Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('post taxonomy - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('post taxonomy - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('post taxonomy - 404');
        },
        403: function (xhr) {
          console.log('post taxonomy - 403');
        },
        200: function (xhr) {
          console.log('post taxonomy - 200');
        }
      }
    });


  }
  app.preloader.hide();

}

function clean_link_breaks(text) {

  return JSON.stringify(text);
}

function generate_pdf(nid) {


    app.preloader.show();
    // Get Data from server
    var createPDFproposalurl = "https://pdf.graphicwebdesigninc.com/fillpdf.php?template=gwd_proposal"+
    "&brand="+$('#proposal-5 input.brand').val()+
    "&project_scope_id="+$('#proposal-5 span.project-scope-id').html().replace('Project ID: ','')+
    "&domain="+$('#proposal-5 .domainname').val()+
    "&agent="+$('#proposal-5 .salesagent').val()+
    "&objective="+$('#proposal-5 .objective').val()+
    "&example_domain_1="+$('#proposal-5 .example-domain-one').val()+
    "&example_domain_2="+$('#proposal-5 .example-domain-two').val()+
    "&example_domain_3="+$('#proposal-5 .example-domain-three').val()+
    "&findings="+$('#proposal-5 .findings').val()+
    "&recommendations="+$('#proposal-5 .recommendations').val()+
    "&webpages="+$('#proposal-5 .webpages').val()+
    "&features="+$('#proposal-5 .features').val()+
    "&timeline="+$('#proposal-5 .timeline').val()+"--*Timelines greatly depend on prompt client feedback (i.e. photography, content, and logins)"+
    "&lineitem1="+$('#proposal-5 .line1').val()+
    "&lineitemcost1="+$('#proposal-5 .line1cost').val()+
    "&lineitem2="+$('#proposal-5 .line2').val()+
    "&lineitemcost2="+$('#proposal-5 .line2cost').val()+
    "&lineitem3="+$('#proposal-5 .line3').val()+
    "&lineitemcost3="+$('#proposal-5 .line3cost').val()+
    "&lineitem4="+$('#proposal-5 .line4').val()+
    "&lineitemcost4="+$('#proposal-5 .line4cost').val()+
    "&lineitem5="+$('#proposal-5 .line5').val()+
    "&lineitemcost5="+$('#proposal-5 .line5cost').val()+
    "&lineitem6="+$('#proposal-5 .line6').val()+
    "&lineitemcost6="+$('#proposal-5 .line6cost').val()+
    "&lineitem7="+$('#proposal-5 .line7').val()+
    "&lineitemcost7="+$('#proposal-5 .line7cost').val()+
    "&payments1="+$('#proposal-5 .payments1').val()+
    "&payments2="+$('#proposal-5 .payments2').val()+
    "&payments3="+$('#proposal-5 .payments3').val()+
    "&paymentscost1="+$('#proposal-5 .payments1cost').val()+
    "&paymentscost2="+$('#proposal-5 .payments2cost').val()+
    "&paymentscost3="+$('#proposal-5 .payments3cost').val()+
    "&additional1=Domain Registration with Privacy"+
    "&additionalcost1=2/mo"+
    "&additional2=Branded Email Addresses with Support"+
    "&additionalcost2=8/mo/box"+
    "&additional3=Graphic Design and Promo Materials"+
    "&additionalcost3=45/hr"+
    "&additional4=Web or App Development"+
    "&additionalcost4=80/hr"+
    "&additional5=Search Engine Optimization (SEO)"+
    "&additionalcost5=600/mo"+
    "&additional6=Internet Radio Ads"+
    "&additionalcost6=Inquire"+
    "&additional7=Content Writing, Photography, Video"+
    "&additionalcost7=Inquire";


    //console.log(createPDFproposalurl);

    app.request({
      url: createPDFproposalurl,
      method: 'POST',
      //dataType:'json',
      headers: {
        //"X-CSRF-Token":localStorage.getItem('csrf'),
        //"Content-Type":"application/json"
        //"Authorization":"Basic  "+localStorage.getItem('auth_cred')
      },
      //data: proposalData,
      success: function (data, status, xhr) {

          console.log("create pdf proposal success");
          //console.log("register.done data = "+data);

          $(".page-current .debug").html('');
          $('.page-current .debug').html('<span class="success-message">PDF Creation - Success</span>');

          console.log(xhr.responseText);


          update_lead_proposal_file_url(xhr.responseText,nid);

          app.preloader.hide();

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        //console.log(leadData);
        console.log(xhr);
        console.log( "create pdf proposal error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Error Creating PDF for Proposal<br />'+xhr.responseText+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('create pdf proposal - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('create pdf proposal - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('create pdf proposal - 404');
        },
        403: function (xhr) {
          console.log('create pdf proposal - 403');
        },
        200: function (xhr) {
          console.log('create pdf proposal - 200');
        }
      }
    });


}


function create_proposal(nid) {


    app.preloader.show();
    // Get Data from server
    var createProposalurl = APIdomain+'api/1.0/node.json';


    var proposalData = '{"type":"proposal_and_project_scope",'+
      '"title_field":{"und":[{"value":"'+$('#proposal-5 .brand').val()+'"}]},'+
      '"field_project_scope_id_text":{"und":[{"value":"'+$('#proposal-5 .project-scope-id').html().replace('Project ID: ','')+'"}]},'+
      //'"field_phone_number":{"und":[{"value":"'+$('#proposal-5 .phone').val()+'"}]},'+
      //'"field_email":{"und":[{"value":"'+$('#proposal-5 .email').val()+'"}]},'+
      '"field_domain_name_text":{"und":[{"value":"'+$('#proposal-5 input.domainname').val()+'"}]},'+
      '"field_sales_agent_text":{"und":[{"value":"'+$('#proposal-5 input.salesagent').val()+'"}]},'+
      '"field_page_1_objective_list_1":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.objective').val())+'}]},'+
      '"field_page_1_design_examp_list_1":{"und":[{"value":"'+$('#proposal-5 input.example-domain-one').val()+'"}]},'+
      '"field_page_1_design_examp_list_2":{"und":[{"value":"'+$('#proposal-5 input.example-domain-two').val()+'"}]},'+
      '"field_page_1_design_examp_list_3":{"und":[{"value":"'+$('#proposal-5 input.example-domain-three').val()+'"}]},'+
      '"field_page_3_scope_a_findings":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.findings').val())+'}]},'+
      '"field_page_3_scope_b_recommendat":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.recommendations').val())+'}]},'+
      '"field_page_3_scope_c_user_interf":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.webpages').val())+'}]},'+
      '"field_page_3_scope_d_features":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.features').val())+'}]},'+
      '"field_page_3_scope_e_timeline":{"und":[{"value":'+clean_link_breaks($('#proposal-5 textarea.timeline').val())+'}]},'+
      '"field_page_6_line_item_1":{"und":[{"value":"'+$('#proposal-5 input.line1').val()+'"}]},'+
      '"field_page_6_line_item_1_cost":{"und":[{"value":"'+$('#proposal-5 input.line1cost').val()+'"}]},'+
      '"field_page_6_line_item_2":{"und":[{"value":"'+$('#proposal-5 input.line2').val()+'"}]},'+
      '"field_page_6_line_item_2_cost":{"und":[{"value":"'+$('#proposal-5 input.line2cost').val()+'"}]},'+
      '"field_page_6_line_item_3":{"und":[{"value":"'+$('#proposal-5 input.line3').val()+'"}]},'+
      '"field_page_6_line_item_3_cost":{"und":[{"value":"'+$('#proposal-5 input.line3cost').val()+'"}]},'+
      '"field_page_6_line_item_4":{"und":[{"value":"'+$('#proposal-5 input.line4').val()+'"}]},'+
      '"field_page_6_line_item_4_cost":{"und":[{"value":"'+$('#proposal-5 input.line4cost').val()+'"}]},'+
      '"field_page_6_line_item_5":{"und":[{"value":"'+$('#proposal-5 input.line5').val()+'"}]},'+
      '"field_page_6_line_item_5_cost":{"und":[{"value":"'+$('#proposal-5 input.line5cost').val()+'"}]},'+
      '"field_page_6_line_item_6":{"und":[{"value":"'+$('#proposal-5 input.line6').val()+'"}]},'+
      '"field_page_6_line_item_6_cost":{"und":[{"value":"'+$('#proposal-5 input.line6cost').val()+'"}]},'+
      '"field_page_6_line_item_7":{"und":[{"value":"'+$('#proposal-5 input.line7').val()+'"}]},'+
      '"field_page_6_line_item_7_cost":{"und":[{"value":"'+$('#proposal-5 input.line7cost').val()+'"}]},'+
      '"field_payments_1":{"und":[{"value":"'+$('#proposal-5 input.payments1cost').val()+'"}]},'+
      '"field_payments_2":{"und":[{"value":"'+$('#proposal-5 input.payments2cost').val()+'"}]},'+
      '"field_payments_3":{"und":[{"value":"'+$('#proposal-5 input.payments3cost').val()+'"}]}'+
      '}';

      //alert($('#lead-form .fullname').val());

    //console.log(proposalData);


    app.request({
      url: createProposalurl,
      method: 'POST',
      dataType:'json',
      headers: {
        "X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json",
        "Authorization":"Basic  "+localStorage.getItem('auth_cred')
      },
      data: proposalData,
      success: function (data, status, xhr) {

          console.log("create proposal data success");
          //console.log("register.done data = "+data);

          $(".page-current .debug").html('');
          $('.page-current .debug').html('<span class="success-message">Success</span>');

          // Generate PDF
          generate_pdf(nid);

          app.preloader.hide();

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        //console.log(leadData);
        console.log( "create proposal error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Error Creating Proposal<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('create proposal - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('create proposal - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('create proposal - 404');
        },
        403: function (xhr) {
          console.log('create proposal - 403');
        },
        200: function (xhr) {
          console.log('create proposal - 200');
        }
      }
    });

}


const capitalize_first_letter = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


function proposal_actions(nid) {


  console.log(nid);
  if (nid) {
    // Get Lead Data from server
    var getNodeUrl = APIdomain+'api/1.0/node/'+nid+'.json';

    app.preloader.show();

      app.request.json(getNodeUrl, { foo:'bar', id: 5 }, function (data) {


          //console.log(data);
          console.log('setting proposal form');

              //data[i].name
              //data[i].posted_date
              //data[i].method_of_contact
              //data[i].phone_menu_option
              //data[i].nid


          // Set Data
          $('.proposal-content-section .fullname').html('Name: '+data.title);
          if(data.field_business_name.und){
            $('.proposal-content-section .businessname').html('Brand: '+data.field_business_name.und[0].value);
            $('input.brand').val(data.field_business_name.und[0].value);
          }
          $('.proposal-content-section .created').text('CRM Entry Created: '+timeConverter(data.created));
          if(data.field_phone_number.und){$('.proposal-content-section .phone').html('Phone: '+data.field_phone_number.und[0].value);}
          if(data.field_email.und){$('.proposal-content-section .email').html('Email: '+data.field_email.und[0].value);}
          if(data.field_domain_name_text.und){
            $('.proposal-content-section .domain').html('Domain: '+data.field_domain_name_text.und[0].value);
            $('input.domainname').val(data.field_domain_name_text.und[0].value);
          }
          if(data.field_sales_agent_text.und){
            
              if (data.field_sales_agent_text.und[0].value == 'steven') {
                $('input.salesagent').val('Steven Spencer');
                $('.proposal-content-section .sales-agent').html('Sales Agent: Steven Spencer');
              }
              if (data.field_sales_agent_text.und[0].value == 'tony') {
                $('input.salesagent').val('Tony Aromandi');
                $('.proposal-content-section .sales-agent').html('Sales Agent: Tony Aromandi');
              }

            //$('input.salesagent').val(capitalize_first_letter(data.field_sales_agent_text.und[0].value));
          }
          //if(data.field_status_text.und){$('.proposal-content-section .status').val(data.field_status_text.und[0].value);}
          if(data.field_existing_website.und){
            var existing_website_text = '';
            switch (data.field_existing_website.und[0].value) {
              case 'redesign' :
                existing_website_text = 'Redesign';
              break;
              case 'new' : 
                existing_website_text = 'New Website';
              break;
              case 'retainer' :
                existing_website_text = 'Retainer / Development Only';
              break;
              case 'hosting' :
                existing_website_text = 'Managed Hosting / Updates';
              break;
            }
            $('.proposal-content-section .existing-website').html('Existing Website? '+existing_website_text);
          }
          if(data.field_logo.und){
            var logo_text = '';
            switch (data.field_logo.und[0].value) {
              case 'existing' :
                logo_text = 'Already Has A Logo';
              break;
              case 'redesign' :
                logo_text = 'Wants to Redesign Logo';
              break;
              case 'new' :
                logo_text = 'Needs a Logo';
              break;
            }
            $('.proposal-content-section .logo').html('Logo? '+logo_text);
          }
          //if(data.field_lead_for_business_text.und){$('.proposal-content-section .business_lead_name').val(data.field_lead_for_business_text.und[0].value);}
          //if(data.field_method_of_contact.und){$('.proposal-content-section .method_of_contact').val(data.field_method_of_contact.und[0].value);}
          //if(data.field_phone_menu_option.und){$('.proposal-content-section .phone-menu-option').val(data.field_phone_menu_option.und[0].value);}

          // Sales Notes
          //if(data.field_notes_sales_notes.und){$('.proposal-content-section .sales-notes-log').html(convertLineBreaks(decodeURI(data.field_notes_sales_notes.und[0].value)));}
          

          $('.line-items-expand').on('click',function(){

            $('.line-items-expand').addClass('open');

            $('#line-items .expandable').removeClass('hide');
            $('#line-items .expandable').addClass('visible');

          })


          // Revision Date
          $('.proposal-content-section .last-revised').text('Updated: '+timeConverter(data.revision_timestamp));

          // Header Info
          $('.proposal-leads-updated-date').html(header_updated_versioninfo());

          // Project Scope ID
          new_document_id();
          // $('.project-scope-id').html('Project ID: '+new_document_id());

          app.preloader.hide();
    });


    $('.proposal-content-section .industry').on('change',function(){

      get_term_details(this.options[this.selectedIndex].getAttribute('data-tid'));
      //console.log('tid = '+this.options[this.selectedIndex].getAttribute('data-tid'));
      //get_term_details(tid);

    });



    $('#proposal-5 .generate-proposal').on('click',function(){

      create_proposal(nid);

    });

    $('#proposal-5 .line1cost').on('change',function(){
      check_math();
    });
    $('#proposal-5 .line2cost').on('change',function(){
      check_math();
    });
    $('#proposal-5 .line3cost').on('change',function(){
      check_math();
    });
    $('#proposal-5 .line4cost').on('change',function(){
      check_math();
    });
    $('#proposal-5 .line5cost').on('change',function(){
      check_math();
    });
    $('#proposal-5 .line6cost').on('change',function(){
      console.log('new total set - '+$(this).val());
      set_payment_amounts($(this).val());
    });




  } // end if nid
}

function update_lead_proposal_file_url(proposal_file_url,nid,project_scope_id) {


    app.preloader.show();
    // Get Data from server
    var updateleadurl = APIdomain+'api/1.0/node/'+nid+'.json';

    //console.log(salesNotes);

    console.log($('#proposal-5 span.project-scope-id').html().replace('Project ID: ',''));


    var leadData = '{"field_proposal_generated":{"und":[{"value":"'+proposal_file_url+'"}]},'+
    '"field_status_text":{"und":[{"value":"proposal_approval_pending"}]},'+
    '"field_project_scope_id_text":{"und":[{"value":"'+$('#proposal-5 span.project-scope-id').html().replace('Project ID: ','')+'"}]}'+
    '}';
    

      //alert($('#lead-form .fullname').val());

      console.log(leadData);


    app.request({
      url: updateleadurl,
      method: 'PUT',
      dataType:'json',
      headers: {
        "X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json",
        "Authorization":"Basic  "+localStorage.getItem('auth_cred')
      },
      data: leadData,
      success: function (data, status, xhr) {

          console.log("update lead data success");
          //console.log("register.done data = "+data);

          //$(".page-current .debug").html('');
          //$('.page-current .debug').html('<span class="success-message">Success</span>');

          app.preloader.hide();

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        console.log( "update lead error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        //$(".page-current .debug").html('');
        //$(".page-current .debug").append('<span class="error-message">Update Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('update lead - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('update lead - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('update lead - 404');
        },
        403: function (xhr) {
          console.log('update lead - 403');
        },
        200: function (xhr) {
          console.log('update lead - 200');
        }
      }
    });

}



function update_lead(nid,salesNotes) {


    app.preloader.show();
    // Get Data from server
    var updateleadurl = APIdomain+'api/1.0/node/'+nid+'.json';

    //console.log(salesNotes);


    var leadData = '{"title_field":{"und":[{"value":"'+$('.page-current #crm-form .fullname').val()+'"}]},'+
      '"field_business_name":{"und":[{"value":"'+$('.page-current #crm-form .businessname').val()+'"}]},'+
      '"field_phone_number":{"und":[{"value":"'+$('.page-current #crm-form .phone').val()+'"}]},'+
      '"field_email":{"und":[{"value":"'+$('.page-current #crm-form .email').val()+'"}]},'+
      '"field_domain_name_text":{"und":[{"value":"'+$('.page-current #crm-form .domain').val()+'"}]},'+
      '"field_sales_agent_text":{"und":[{"value":"'+$('.page-current #crm-form .sales-agent').val()+'"}]},'+
      '"field_status_text":{"und":[{"value":"'+$('.page-current #crm-form .status').val()+'"}]},'+
      '"field_existing_website":{"und":[{"value":"'+$('.page-current #crm-form .existing-website').val()+'"}]},'+
      '"field_logo":{"und":[{"value":"'+$('.page-current #crm-form .logo').val()+'"}]},'+
      '"field_method_of_contact":{"und":[{"value":"'+$('.page-current #crm-form .method_of_contact').val()+'"}]},'+
      '"field_address":{"und":[{"country": "us","street":"'+$('#crm-form .businessaddress').val()+'","city": "'+$('#crm-form .businesscity').val()+'","province": "'+$('#crm-form select.state').val()+'","postal_code": "'+$('#crm-form .businesszip').val()+'"}]},'+
      '"field_web_design_example_domain":{"und":[{"value":"'+$('.page-current .reference-website-1').val()+'"}]},'+
      '"field_web_design_example_domain2":{"und":[{"value":"'+$('.page-current .reference-website-2').val()+'"}]},'+
      '"field_web_design_example_domain3":{"und":[{"value":"'+$('.page-current .reference-website-3').val()+'"}]},'+
      '"field_lead_for_business_text":{"und":[{"value":"'+$('.page-current #crm-form .business_lead_name').val()+'"}]},'+
      '"field_phone_menu_option":{"und":[{"value":"'+$('.page-current #crm-form .phone-menu-option').val()+'"}]},'+
      '"field_notes_sales_notes":{"und":[{"value":"'+jsonEscape(salesNotes)+'"}]}'+
      '}';

      //alert($('#lead-form .fullname').val());

      //console.log(leadData);


    app.request({
      url: updateleadurl,
      method: 'PUT',
      dataType:'json',
      headers: {
        "X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json",
        "Authorization":"Basic  "+localStorage.getItem('auth_cred')
      },
      data: leadData,
      success: function (data, status, xhr) {

          console.log("update lead data success");
          //console.log("register.done data = "+data);

          $(".page-current .debug").html('');
          $('.page-current .debug').html('<span class="success-message">Success</span>');

          app.preloader.hide();

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        console.log( "update lead error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Update Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('update lead - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('update lead - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('update lead - 404');
        },
        403: function (xhr) {
          console.log('update lead - 403');
        },
        200: function (xhr) {
          console.log('update lead - 200');
        }
      }
    });

}





function register_lead() {


    app.preloader.show();
    // Get Data from server
    var registerleadurl = APIdomain+'api/1.0/node.json';

    //console.log($('#lead-form select.state').val());

    var leadData = '{"type":"contact_sales_lead",'+
      '"title_field":{"und":[{"value":"'+$('#lead-form .fullname').val()+'"}]},'+
      '"field_business_name":{"und":[{"value":"'+$('#lead-form .businessname').val()+'"}]},'+
      '"field_phone_number":{"und":[{"value":"'+$('#lead-form .phone').val()+'"}]},'+
      '"field_email":{"und":[{"value":"'+$('#lead-form .email').val()+'"}]},'+
      '"field_domain_name_text":{"und":[{"value":"'+$('#lead-form .domain').val()+'"}]},'+
      '"field_sales_agent_text":{"und":[{"value":"'+$('#lead-form .sales-agent').val()+'"}]},'+
      '"field_status_text":{"und":[{"value":"'+$('#lead-form .status').val()+'"}]},'+
      '"field_existing_website":{"und":[{"value":"'+$('#lead-form .existing-website').val()+'"}]},'+
      '"field_logo":{"und":[{"value":"'+$('#lead-form .logo').val()+'"}]},'+
      '"field_domain_name":{"und":[{"value":"'+$('#lead-form .domain').val()+'"}]},'+
      '"field_method_of_contact":{"und":[{"value":"'+$('#lead-form .method_of_contact').val()+'"}]},'+
      '"field_address":{"und":[{"country": "us","street":"'+$('#lead-form .businessaddress').val()+'","city": "'+$('#lead-form .businesscity').val()+'","province": "'+$('#lead-form select.state').val()+'","postal_code": "'+$('#lead-form .businesszip').val()+'"}]},'+
      '"field_web_design_example_domain":{"und":[{"value":"'+$('#lead-form .reference-website-1').val()+'"}]},'+
      '"field_web_design_example_domain2":{"und":[{"value":"'+$('#lead-form .reference-website-2').val()+'"}]},'+
      '"field_web_design_example_domain3":{"und":[{"value":"'+$('#lead-form .reference-website-3').val()+'"}]},'+
      '"field_lead_for_business_text":{"und":[{"value":"'+$('#lead-form .business_lead_name').val()+'"}]},'+
      '"field_phone_menu_option":{"und":[{"value":"'+$('#lead-form .phone-menu-option').val()+'"}]},'+
      '"field_notes_sales_notes":{"und":[{"value":"'+ jsonEscape($('#lead-form .notes').val())+'"}]}'+
      '}';

      //alert($('#lead-form .fullname').val());

      //console.log(leadData);


    app.request({
      url: registerleadurl,
      method: 'POST',
      dataType:'json',
      headers: {
        //"X-CSRF-Token":localStorage.getItem('csrf'),
        "Content-Type":"application/json"
      },
      data: leadData,
      success: function (data, status, xhr) {

          console.log("lead data success");
          //console.log("register.done data = "+data);

          $(".page-current .debug").html('');
          $('.page-current .debug').html('<span class="success-message">Success</span>');

          app.preloader.hide();

          // Set third parament to new_account to represent a newly registered account, this will login the user after CSRF is acquired
          //get_CSRF(regJSON.mail,regJSON.pass,'new_account',accountType);

          //console.log('registration complete with csrf token = '+localStorage.getItem('csrf'));
      },
      error: function (xhr,status) {
        // Error
        console.log(leadData);
        console.log( "post lead error" );
        //console.log("register.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $(".page-current .debug").append('<span class="error-message">Registration Error<br />'+JSON.parse(xhr.responseText)+'</div>');
        app.preloader.hide();
      },
      statusCode: {
        503: function (xhr) {
          console.log('post lead - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        406: function (xhr) {
          console.log('post lead - 406');
          //console.log(xhr);
        },
        404: function (xhr) {
          console.log('post lead - 404');
        },
        403: function (xhr) {
          console.log('post lead - 403');
        },
        200: function (xhr) {
          console.log('post lead - 200');
        }
      }
    });

}


function newlead_actions(){

  $('#lead-form .submit').click(function(){

    register_lead();

  });

}





function get_CSRF(email,password,action,account_type = null) {
  // Get Data from server
  app.preloader.show();

  var csrfdataurl = APIdomain+'services/session/token';

  app.request({
    url: csrfdataurl,
    method: 'GET',
    //async:false,
    dataType:'text',
    contentType: 'application/json',
    headers: {
      "Content-Type":"application/json"
    },
    //processData: false,
    success: function (data, status, xhr) {
        // Success
        if (action == 'login') {
          console.log("csrf token acquired before login = "+data);
          console.log('register_only !== true');
          // Set CSRF 
          localStorage.setItem('csrf',data);
          // Set user session values
          //localStorage.setItem('auth_cred',btoa(email+':'+password));
          // Login User
          //app.preloader.hide();

          //logout_user('passwordresetuser','Test1234!');
          login_user(email,password,action);
        }

    },
    error: function (xhr,status, responseText) {
        app.preloader.hide();
        console.log( "csrf error" );
        console.log("csrf.fail data = "+JSON.stringify(xhr));
        $(".page-current .debug").html('');
        $('.page-current .debug').html('<span class="error-message">CSRF Error<br />'+JSON.parse(xhr.responseText)+'</span>');
      },
      statusCode: {
        503: function (xhr) {
          console.log('csrf - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        404: function (xhr) {
          console.log('csrf - 404');
          alert('page not found');
        },
        403: function (xhr) {
          console.log('csrf - 403');
          //console.log(xhr);
        },
        401: function (xhr) {
          console.log('csrf - 401');
          //console.log(xhr);
        },
        200: function (xhr) {
          console.log('csrf - 200');
        }
      }
    });

}



// Check current push notification ID again that on our push server, if its wrong, update the server
function check_existing_push_id(server_push_id,updateData) {



  // If we need it in place where we don't have access to app instance or before we init the app
  if (app.device.ios) {
    console.log('It is iOS device');
    localStorage.setItem('device_platform','ios');

  // Only update the device push IDs for smartphones
  //if (localStorage.getItem('device_platform') == 'ios' || localStorage.getItem('device_platform') == 'android') {

    updateData = '"field_device_type":{"und":[{"value":"'+localStorage.getItem('device_platform')+'"}]},'+
          '"field_device_push_id_gcm":{"und":[{"value":"'+localStorage.getItem('push_registraton_id')+'"}]},'+
          '"field_device_push_id_apn":{"und":[{"value":"'+localStorage.getItem('push_registraton_id')+'"}]}';

    if (localStorage.getItem('push_registraton_id') !== server_push_id) {

      console.log('updating push ID for incorrect server push ID');
      // Update Server Push IDs
      if (localStorage.getItem('device_platform') == 'ios' || localStorage.getItem('device_platform') == 'android') {
        update_user(updateData);
      }
      
    }

    // Add A New ID for an existing account
    if (server_push_id == 'new') {
      console.log('updating push ID for account with no server push ID');
      // Update Server Push IDs
      update_user(updateData);
    }



  }
  // Update push for android users
  if (app.device.android) {
    console.log('It is Android device');
    localStorage.setItem('device_platform','android');


  // Only update the device push IDs for smartphones
  //if (localStorage.getItem('device_platform') == 'ios' || localStorage.getItem('device_platform') == 'android') {

    updateData = '"field_device_type":{"und":[{"value":"'+localStorage.getItem('device_platform')+'"}]},'+
          '"field_device_push_id_gcm":{"und":[{"value":"'+localStorage.getItem('push_registraton_id')+'"}]},'+
          '"field_device_push_id_apn":{"und":[{"value":"'+localStorage.getItem('push_registraton_id')+'"}]}';

    if (localStorage.getItem('push_registraton_id') !== server_push_id) {

      console.log('updating push ID for incorrect server push ID');
      // Update Server Push IDs
      if (localStorage.getItem('device_platform') == 'ios' || localStorage.getItem('device_platform') == 'android') {
        update_user(updateData);
      }
      
    }

    // Add A New ID for an existing account
    if (server_push_id == 'new') {
      console.log('updating push ID for account with no server push ID');
      // Update Server Push IDs
      update_user(updateData);
    }




  }




  if (app.device.browser) {
    console.log('It is Browser device');
    localStorage.setItem('device_platform','browser');
  }

  //}

}



function login_user(email,password,action) {
  app.preloader.show();

  // form validation 
  if (!email || !password) {
      $(".page-current .debug").html('');
      $(".page-current .debug").html('<div class="error-message">You must provide an email and password.</div>');
      app.preloader.hide();
  } else {
  // Get Data from server
  var logindataurl = APIdomain+'api/1.0/user/login.json';
  // Assign handlers immediately after making the request,
  // and remember the jqXHR object for this request

  var loginData = '{"username":"'+email+'","password":"'+password+'"}';
  //console.log(loginData);

  console.log('logging in with csrf token = '+localStorage.getItem('csrf'));

  app.request({
    url: logindataurl,
    method: 'POST',
    dataType:'json',
    //async:false,
    contentType: 'application/json',
    headers: {
      "X-CSRF-Token":localStorage.getItem('csrf'),
      "Content-Type":"application/json"
    },
    data: loginData,
    processData: false,
    success: function (data, status, xhr) {


        console.log( "login done - success" );
        console.log(data.user.mail);
        //console.log(data);

        $('.login-menu').text('Logout');
        $('.login-menu').addClass('logout');

        $('.logout').on('click',function(){
          logout_user(data.user.mail);
        });

        $('.avatar img').attr('src',data.user.picture.url);
        localStorage.setItem('user_picture',data.user.picture.url);

        localStorage.setItem('username',data.user.mail);
        localStorage.setItem('uid',data.user.uid);

        // Push ID Checkup
        if (data.user.field_ios_device_token['und']) {

          console.log('existing push ID = '+data.user.field_ios_device_token.und[0].value);

          check_existing_push_id(data.user.field_ios_device_token.und[0].value);
          console.log('existing push ID = '+data.user.field_ios_device_token.und[0].value);
        } else {
          // No ID Exists, create one
          console.log('no existing push ID');
          check_existing_push_id('new');
        }



        if(data.session_name){localStorage.setItem('session_name',data.session_name);}
        if(data.sessid){localStorage.setItem('sessid',data.sessid);}


        localStorage.setItem('sessid',data.sessid);

        // Session Cookie to local storage
        localStorage.setItem(data.session_name,data.sessid);

        var cookie = data.session_name+'='+data.sessid;

        var expires;
        var exdays = 1;
        if (exdays) {
            var date = new Date();
            date.setTime(date.getTime()+(exdays*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        else{
            expires = "";
        }

        document.cookie = cookie +expires+"; path=/"; 


        // Session Cookie to local storage
        localStorage.setItem(data.session_name,data.sessid);

        if(data.token){
          // Set CSRF in session
          localStorage.setItem('csrf',data.token);
        };
        console.log('csrf token after login complete = '+data.token);
   
        // Set Basic Authentication
        localStorage.setItem('auth_cred',btoa(data.user.mail+':'+password));


        $(".page-current .debug").html('');
        $(".page-current .debug").html('<div class="success-message">Login Successful</div>');
        

        //console.log('XHR = '+JSON.stringify(xhr));

        $('#login-1 .logout').html('Logout');
        $('.panel-left .menu-logout').html('Logout');

        get_crm_sales_gwd_actions();
        //homeView.router.navigate('/');
        app.views.main.router.navigate('/');
        app.preloader.hide();



        // Push ID Checkup
        if (data.user.field_device_push_id_apn['und']) {

          console.log('existing push ID checkpoint 1 = '+data.user.field_device_push_id_apn.und[0].value);

          check_existing_push_id(data.user.field_device_push_id_apn.und[0].value);
          //console.log('existing push ID = '+data.user.field_device_push_id_apn.und[0].value);
        } else {
          // No ID Exists, create one
          console.log('no existing push ID');
          check_existing_push_id('new');
        }


      },
      error: function (xhr,status, responseText) {
        app.preloader.hide();
        console.log( "login error" );
        //console.log("login.fail data = "+JSON.stringify(xhr));


        $(".page-current .debug").html('');
        $('.page-current .debug').html('<span class="error-message">Login Error<br />'+JSON.parse(xhr.responseText)+'</span>');
      },
      statusCode: {
        503: function (xhr) {
          console.log('login - 503');
          alert('Site undergoing maintenance, please try again shortly.');
        },
        404: function (xhr) {
          console.log('login - 404');
          alert('page not found');
        },
        403: function (xhr) {
          console.log('login - 403');
          //console.log(xhr);
        },
        401: function (xhr) {
          console.log('login - 401');
          //console.log(xhr);
        },
        200: function (xhr) {
          //console.log('login - 200');
        }
      }
    }); 
  } // End form validation
}




function update_user(updateData,uid) {
  app.preloader.show();

  uid = localStorage.getItem('uid');
  // Get Data from server
  var updatedataurl = APIdomain+'api/1.0/user/'+uid+'.json';

  updateData = '{'+updateData+'}';
  //console.log(updateData);

  app.request({
    method: "PUT",
    url: updatedataurl,
    data: updateData,
    contentType: 'application/json',
    dataType:'json',
    processData: false,
    headers: {
      "X-CSRF-Token":localStorage.getItem('csrf'),
      "Content-Type":"application/json",
      "Authorization":"Basic  "+localStorage.getItem('auth_cred')
    },
    success: function (data, status, xhr) {
      console.log("update user data success");
      //console.log("update user data success = "+JSON.stringify(data));
      app.preloader.hide();

    },
    error: function(xhr, status) {
      console.log("update user data error");
      //console.log("update user data error = "+JSON.stringify(xhr));
      $(".page-current .debug").html('');
      app.preloader.hide();
      console.log(xhr.responseText);
      $(".page-current .debug").append('<div class="message">'+xhr.responseText+'</div>');
    },
    statusCode: {
      503: function (xhr) {
        console.log('update user - 503');
        alert('Site undergoing maintenance, please try again shortly.');
      },
      406: function (xhr) {
        console.log('update user - 406');
        //console.log(xhr);
      },
      404: function (xhr) {
        console.log('update user - 404');
        //console.log(xhr);
      },
      403: function (xhr) {
        console.log('update user - 403');
        //console.log(xhr);
      }
    }
  });
}




function clear_all_cookies() {
  localStorage.clear();
}
// Clear cookies when app loads
//console.log('localStorage = '+JSON.stringify(localStorage));
//clear_all_cookies();

function logout_user(username) {

  username = localStorage.getItem('username');
  // Get Data from server
  var logoutdataurl = APIdomain+'api/1.0/user/logout.json';
  app.preloader.show();
  console.log('attempting logout for '+username);
  console.log('attempting logout with csrf '+localStorage.getItem('csrf'));

  var logoutData = '{"username":"'+localStorage.getItem('username')+'"}';

  loggedIn = false;


  app.request({
    url: logoutdataurl,
    method: 'POST',
    dataType:'text',
    contentType: 'application/json',
    headers: {
      "X-CSRF-Token":localStorage.getItem('csrf'),
      "Content-Type":"application/json",
      "Authorization":"Basic  "+localStorage.getItem('auth_cred')
      //"X-Cookie":localStorage.getItem('session_name')+'='+localStorage.getItem('sessid')
    },
    data: logoutData,
    success: function (data, status, xhr) {
      // Success
      console.log("logout data success");
      console.log("logout.done data = "+data);

      // Wipe all session data / cookies except the devicetype
      var device_type = localStorage.getItem('device_platform');
      clear_all_cookies();
      localStorage.setItem('device_platform',device_type);
      localStorage.setItem('uid','0');
      
      // Reset UI and return to login screen
      //var mainView = app.view.main;
      app.views.main.router.navigate('/login');
      //loginView.router.navigate('/login/');
      $('.login-menu').text('Login');
      $('.login-menu').removeClass('logout');

      // UI Messages
      $(".page-current .debug").html('');
      $(".page-current .debug").html('<div class="success-message">Logout Successful</div>');
      $("#login-form .debug").html('<div class="success-message">Logout Successful</div>');
      $('.avatar img').attr('src','/static/avatar-default.png');
      app.preloader.hide();
    },
    error: function (xhr,status) {
      // Error
      app.preloader.hide();
      console.log("logout error");
      //console.log("logout.fail data = "+JSON.stringify(xhr));
      $(".page-current .debug").html('');
      $(".page-current .debug").html('<div class="error-message">Logout Error<br'+JSON.parse(xhr.responseText)+'</div>');
      //homeView.router.navigate('/');
      
    },
    statusCode: {
      503: function (xhr) {
        console.log('logout - 503');
        alert('Site undergoing maintenance, please try again shortly.');
      },
      406: function (xhr) {
        app.preloader.hide();
        console.log('logout - 406');
        //console.log(xhr);
      },
      404: function (xhr) {
        console.log('logout - 404');
      },
      403: function (xhr) {
        console.log('logout - 403');
      },
      200: function (xhr) {
        console.log('logout - 200');
      }
    }
  });



}



function login_actions(email,password) {

  $('#login-form .submit').on('click',function(){

    console.log('click');

    //email = $('#login-form input.email').val();
    //alert(email);

    login_user($('#login-form .email').val(),$('#login-form .password').val(),'login');


  });

}



app.on('pageInit', function (page) {
  //console.log('pageInit: '+page.name);


  // Check for existing session
  if(localStorage.getItem('user_picture')) {
    //console.log('we have a picture url '+localStorage.getItem('user_picture'));
    $('.avatar img').attr('src',localStorage.getItem('user_picture'));
  }

  //app.panel.enableSwipe('left');

  console.log('PUSH ID = '+localStorage.getItem('push_registraton_id'));

  switch (page.name) {
    //hello 
    case 'home' :
      //app.tab.show('#melodyave', true);
      get_crm_sales_gwd_actions();
    break;
    case 'login':
      //console.log('page_Init set: '+page.name);
      //localStorage.setItem('page_session',page.name);
      //login_actions();
    break;
    case 'melodyave':
      console.log('page_Init set: '+page.name);
      //localStorage.setItem('page_session',page.name);
      get_crm_sales_melodyave_actions();
    break;
    case 'newlead':
    console.log('page_Init set: '+page.name);
      //app.preloader.show();
      //app.panel.disableSwipe('left');
      newlead_actions();
    break;
    case 'crm':
      console.log('page_Init set: '+page.name);
      //app.preloader.show();
      //app.panel.disableSwipe('left');
      crm_actions(page.route.query.nid);
    break;
    case 'call':
      console.log('page_Init set: '+page.name);
      //app.preloader.show();
      //app.panel.disableSwipe('left');
      call_actions(page.route.query.url);
    break;
    case 'proposal':
      console.log('page_Init set: '+page.name);
      //app.preloader.show();
      //app.panel.disableSwipe('left');
      get_terms('9'); // Pull down the latest industry data for populating proposals.
      proposal_actions(page.route.query.nid);
    break;
  }
});


app.on('pageAfterIn', function (page) {

  switch (page.name) {
    case 'login' :
      console.log('pageAfterIn set: '+page.name);
      login_actions();
    break;
  }

});



get_crm_sales_gwd_actions();

// Refresh CRM after updating information
function refresh_crm(){
  get_crm_sales_gwd_actions();
  get_crm_sales_melodyave_actions();
}






function push_notification(message) {

  var devicePlatform = device.platform;

  message = encodeURI(message);
  //alert(environment);
  //alert(appName);
  //alert(devicePlatform);
  //alert(localStorage.getItem('push_registraton_id'));
  //alert(message);
  //https://push.561apps.com/index.php?env=sandbox&app_id=sfsca&device_type=ios&device_token=29463ddf8df25d569f52844921d11669a27254c45eea07f1ed4e6ba87fbf631d&payload=hello

  var pushnotificationuserdataurl = 'https://push.561apps.com/index.php?env='+environment+'&app_id='+appName+'&device_type='+devicePlatform+'&device_token='+localStorage.getItem('push_registraton_id')+'&payload='+message;


  console.log('pushnotificationuserdataurl = '+pushnotificationuserdataurl);

  //alert(pushnotificationuserdataurl);

  app.request({
    url: pushnotificationuserdataurl,
    method: 'GET',
    contentType: 'application/json',
    dataType:'json',
    headers: {
      "Content-Type":"application/json"
    },
    success: function (data, status, xhr) {
      //alert("getnotificationuser data success");
        //console.log("getnotificationuser.done data = "+JSON.stringify(data));
    },
    error: function(xhr, status) {
      //alert( "pushnotificationuser error" );
      //alert("pushnotificationuser.fail data = "+JSON.stringify(xhr));
    },
    statusCode: {
      503: function (xhr) {
        console.log('push notifications - 503');
        alert('Site undergoing maintenance, please try again shortly.');
      },
      406: function (xhr) {
        console.log('push notifications - 406');
        console.log(xhr);
      },
      404: function (xhr) {
        console.log('push notifications - 404');
        console.log(xhr);
      },
      403: function (xhr) {
        console.log('push notifications - 403');
        console.log(xhr);
      }
    }
  });
}










$(document).on('deviceready', function(){

  var devicePlatform = device.platform;


  // Push Notifications
  var push = PushNotification.init({
    android: {
      // Add your Google Mobile App SenderId here
      senderID: "642471171823",
      sound: "true",
      vibrate: "true"
    },
    browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    },
    ios: {
        alert: "true",
        badge: "true",
        sound: "true"
    },
    windows: {}
  });
  push.on('registration', function (data) {
        //console.dir(data);
        //console.log('PUSH Event=registration, registrationId=' + data.registrationId)
        localStorage.setItem('push_registraton_id',data.registrationId);
        //alert('Event=registration, registrationId=' + data.registrationId)


        check_existing_push_id();

        //push_notification('hello');
    });
  push.on('notification', function (data) {
        console.log('push notification incoming data = '+data);
        if (data) {
          var message = data.message;
          var dialog = app.dialog.alert(message,'Sales');
          dialog.open();
        }
        //alert('PUSH Event=notification, message=' + data.message)
    });
  push.on('error', function (err) {
        console.log(err);
        //alert('PUSH Event=error, message=' + err.message)
    });



  console.log("Device is definetly ready 0!");
  //window.plugins.webviewcolor.change('#E0EFE8');
  app.statusbar.show();
  app.statusbar.overlaysWebView(false);
  app.statusbar.styleLightContent();
  app.statusbar.backgroundColorByHexString("#f68a1f");


});


// Init Functions
get_crm_sales_gwd_actions();
//view.router.back(view.history[0], { force: true });

//app.initialize();
