/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function () {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner"> 
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="main-title sliding">
          <img src="/static/header_brand.png" />
        </div>
        <div class="right">
          <div class="avatar"><a href="/login/"><img src="/static/avatar-default.png"/></a></div>
          <!--<a class="white link external" href="tel:+15612508863">(561) 250-TUNE</a>-->
        </div>
      </div>
    </div>                     

    <!-- Scrollable page content-->
    <div class="page-content">



          <div id="header-text" class="orange-underline row"><div class="header-wrap"><h3>Sales<div class="header-ui-updated-date gwd-leads-updated-date"></div></h3></div></div>


          <div class="inner-wrapper content-wrapper">

              <div id="filters">
                <div class="row">
                  <div class="checkbox-wrap"><input type="checkbox" class="tony"/><label>Tony</label></div>
                  <div class="checkbox-wrap"><input type="checkbox" class="steven"/><label>Steven</label></div>
                  <div class="checkbox-wrap"><input type="checkbox" class="new" checked/><label>New</label></div>
                </div>
                <div class="row">
                  <div class="checkbox-wrap"><input type="checkbox" class="contacted" checked/><label>Follow Up</label></div>
                  <div class="checkbox-wrap"><input type="checkbox" class="quoted" checked/><label>Quoted</label></div>
                  <div class="checkbox-wrap"><input type="checkbox" class="other"/><label>Other</label></div>
                </div>
              </div>

              <div class="content-section loading-container gwd-leads-content-section table-content-section">

                  <div class="debug"></div>

                  <div id="gwd-leads">

                      <table>

                      </table>

                  </div>

              </div>

          </div>



      <!--
      <div class="block block-strong">
        <p>This is an example of tabs-layout application. The main point of such tabbed layout is that each tab contains independent view with its own routing and navigation.</p>

        <p>Each tab/view may have different layout, different navbar type (dynamic, fixed or static) or without navbar like this tab.</p>
      </div>
      <div class="block-title">Navigation</div>
      <div class="list">
        <ul>
          <li>
            <a href="/about/" class="item-content item-link">
              <div class="item-inner">
                <div class="item-title">About</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/form/" class="item-content item-link">
              <div class="item-inner">
                <div class="item-title">Form</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div class="block-title">Modals</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill popup-open" data-popup="#my-popup">Popup</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill login-screen-open" data-login-screen="#my-login-screen">Login Screen</a>
          </div>
        </div>
      </div>

      <div class="block-title">Panels</div>
      <div class="block block-strong">
        <div class="row">
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="left">Left Panel</a>
          </div>
          <div class="col-50">
            <a href="#" class="button button-raised button-fill panel-open" data-panel="right">Right Panel</a>
          </div>
        </div>
      </div>

      <div class="list links-list">
        <ul>
          <li>
            <a href="/dynamic-route/blog/45/post/125/?foo=bar#about">Dynamic (Component) Route</a>
          </li>
          <li>
            <a href="/load-something-that-doesnt-exist/">Default Route (404)</a>
          </li>
          <li>
            <a href="/request-and-load/user/123456/">Request Data & Load</a>
          </li>
        </ul>
      </div>


      -->

    </div>
  </div>
`
    }
    ;
});