/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function (props, _ref) {
  var $store = _ref.$store;
  var products = $store.getters.products;

  var addProduct = function addProduct() {
    $store.dispatch('addProduct', {
      id: '4',
      title: 'Apple iPhone 12',
      description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
    });
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="catalog">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="title">Catalog</div>
      </div>
    </div>
    <div class="page-content">
      <div class="list links-list">
        <ul>
          ${products.value.map((product) => $h`
            <li><a href="/product/${product.id}/">${product.title}</a></li>
          `)}
        </ul>
      </div>
      ${products.value.length === 3 && $h`
        <div class="block">
          <button class="button button-fill" @click=${addProduct}>Add Product</Button>
        </div>
      `}
    </div>
  </div>
`
    }
    ;
});