/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function (props, _ref) {
  var $f7route = _ref.$f7route,
      $store = _ref.$store;
  var products = $store.getters.products;
  var productId = $f7route.params.id;
  var currentProduct;
  products.value.forEach(function (product) {
    if (product.id === productId) {
      currentProduct = product;
    }
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="product">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner sliding">
        <div class="left">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title">${currentProduct.title}</div>
      </div>
    </div>
    <div class="page-content">
      <div class="block-title">About ${currentProduct.title}</div>
      <div class="block block-strong">
        ${currentProduct.description}
      </div>
    </div>
  </div>
`
    }
    ;
});