/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function () {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="login">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner"> 
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="main-title sliding">
          <img src="/static/header_brand.png" />
        </div>
        <div class="right">
          <div class="avatar"><a href="#"><img src="/static/avatar-default.png"/></a></div>
          <!--<a class="white link external" href="tel:+15612508863">(561) 250-TUNE</a>-->
        </div>
      </div>
    </div>                     

    <!-- Scrollable page content-->
    <div id="login-1" class="page-content">


    <!-- Page Inner -->

      <div data-role="content" class="no-padding-content full-height">


        <div id="header-text" class="orange-underline row"><div class="header-wrap">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="">Back</span>
          </a>
          <h3>Login<div class="header-ui-updated-date login-updated-date"></div></h3></div></div>
        <div class="inner-wrapper content-wrapper">


          <div class="content-section loading-container crm-content-section">


            <form id="login-form">

              <input name="email" class="email form-control" placeholder="Email"/>

              <input name="password" class="password form-control" placeholder="Password"/>

              <div class="debug"></div>

              <a class="submit" href="#">Login</a>
              
            </form>

          </div>

        </div>
      </div>


    <!-- End Page Inner -->


    </div>
  </div>
`
    }
    ;
});