/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function () {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="crm">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner"> 
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="main-title sliding">
          <img src="/static/header_brand.png" />
        </div>
        <div class="right">
          <div class="avatar"><a href="/login/"><img src="/static/avatar-default.png"/></a></div>
          <!--<a class="white link external" href="tel:+15612508863">(561) 250-TUNE</a>-->
        </div>
      </div>
    </div>                     

    <!-- Scrollable page content-->
    <div id="crm-4" class="page-content">


    <!-- Page Inner -->

      <div data-role="content" class="no-padding-content full-height">


        <div id="header-text" class="orange-underline row"><div class="header-wrap">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="">Back</span>
          </a>
          <h3>Details<div class="header-ui-updated-date crm-leads-updated-date"></div></h3></div></div>
        <div class="inner-wrapper content-wrapper">


          <div class="content-section loading-container crm-content-section">


            <span class="call-recording-wrap"><a class="call-recording" href=""><i class="icon material-icons color-white">speaker_2_fill</i></a></span>


            <form id="crm-form">

              <span class="created"></span>
              <span class="last-revised"></span>

              <div class="sales-notes-log">


              </div>

            <label>Contact Name</label>
              <input name="fullname" class="fullname form-control" placeholder="Name"/>

              <label>Business Name / Brand</label>
              <input name="businessname" class="businessname form-control" placeholder="Business Name"/>
              <span class="tip">This will be used in the proposal.</span>


              <label>Phone</label>
              <input name="phone" class="phone form-control" placeholder="Phone"/>

              <label>Email</label>
              <input name="email" class="email form-control" placeholder="Email"/>

              <label>Domain Name?</label>
              <input name="domain" class="domain form-control" placeholder="Domain Name"/>
              <span class="tip">Type current domain name or "TBD"</span>

              <label>Existing Website?</label>
              <select class="existing-website" name="existing">
                <option value="default">Existing Website?</option>
                <option value="redesign">Website Redesign</option>
                <option value="new">New Website</option>
                <option value="retainer">Retainer / Development Only</option>
                <option value="hosting">Managed Hosting / Updates</option>
              </select>

              <label>Existing Logo?</label>
              <select class="logo" name="existing">
                <option value="default">Logo?</option>
                <option value="redesign">Already Have A Logo</option>
                <option value="redesign">Logo Redesign</option>
                <option value="new">Logo Needed</option>
              </select>



<div class="accordion-list">
    <div class="accordion-item">
      <div class="accordion-item-toggle">

                <h3 class="orange-underline-no-margin">Address<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
      </div>
      <div class="accordion-item-content">
              <label>Billing Address</label>
              <input name="businessaddress" class="businessaddress form-control" placeholder="Business Address"/>

              <input name="businesscity" class="businesscitystatezip businesscity form-control" placeholder="City"/>

              <select class="state" name="businessstate businesscitystatezip">
                <option value="FL">Florida</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>

              <input name="businesszip" class="businesscitystatezip businesszip form-control" placeholder="Zip"/>

      </div>
    </div>
          

    <div class="accordion-item">
      <div class="accordion-item-toggle">

                <h3 class="orange-underline-no-margin">Reference Websites<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
      </div>
      <div class="accordion-item-content">

              <span>Copy/paste the full URL (http://*)</span>

              <input name="refwebsite1" class="reference-website-1 form-control" placeholder="Reference Website 1"/>


              <input name="refwebsite2" class="reference-website-2 form-control" placeholder="Reference Website 2"/>

              <input name="refwebsite3" class="reference-website-3 form-control" placeholder="Reference Website 3"/>

      </div>
    </div>



    <div class="accordion-item">
      <div class="accordion-item-toggle">

                <h3 class="orange-underline-no-margin">Additional<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
      </div>
      <div class="accordion-item-content">


              <label>Phone Menu Option</label>
              <input name="phone_menu_option" class="phone-menu-option form-control" placeholder="Interested In"/>

              <label>Contact Method</label>
              <select class="method_of_contact" name="method_of_contact" required>
                <option value="default">Contact Method</option>
                <option value="person">In Person</option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
                <option value="website">Website</option>
                <option value="referral">Referral</option>
                <option value="social">Social Media</option>
                <option value="businesscard">Business Card</option>
                <option value="other">Other</option>
              </select>

              <label>Lead For</label>
              <select class="business_lead_name input-with-value" required>
                <option value="default">Which Business?</option>
                <option value="graphicwebdesigninc">Graphic Web Design, Inc.</option>
                <option value="melodyave">Melody Ave</option>
                <option value="londongrey">London Grey</option>
                <option value="other">Other</option>
              </select>

      </div>
    </div>



    <div class="accordion-item">
      <div class="accordion-item-toggle">

                <h3 class="orange-underline-no-margin">Sales Status / Add Notes<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
      </div>
      <div class="accordion-item-content">


              <label>Sales Agent</label>
              <select class="sales-agent" name="salesagent">
                <option value="new">Sales Agent</option>
                <option value="tony">Tony</option>
                <option value="steven">Steven</option>
                <option value="crystal">Crystal</option>
              </select>   

              <label>Status</label>
              <select class="status" name="status">
                <option value="default">Status</option>
                <option value="new">New</option>
                <option value="contacted">Contacted</option>
                <option value="appointment">Appointment Set</option>
                <option value="no_response">No Response</option>
                <option value="discussion">Discussion</option>
                <option value="proposal_approval_pending">Proposal Pending Approval</option>
                <option value="quoted">Quoted</option>
                <option value="negotiating">Negotiating</option>
                <option value="payment_pending">Deposit / Payment Pending</option>
                <option value="closed">Closed</option>
                <option value="cold">Cold</option>
                <option value="spam">Spam</option>
                <option value="test">Test</option>
                <option value="support">Support / Existing Client</option>
                <option value="duplicate">Duplicate</option>
                <option value="personal">Personal</option>
              </select>

              <label>Sales Notes</label>
              <textarea name="notes" class="notes form-control" placeholder="Notes"></textarea>

          </div>
        </div>
      </div> <!-- End Accordion -->

              <div class="debug"></div>

              <a class="submit" href="#">Update / Save</a>       
              

              <a class="start-proposal" href="/proposal?nid=">Start Proposal</a>
              <span class="tip">If changed, save updates before starting proposal.</span>

              <!-- TODO
              <a class="generate-deposit-invoice" href="/invoice?nid=">Deposit Invoice</a>

              <a class="generate-design-invoice" href="/invoice?nid=">Design Invoice</a>

              <a class="generate-launch-invoice" href="/invoice?nid=">Launch Invoice</a>  
              -->            

            </form>

          </div>

        </div>
      </div>


    <!-- End Page Inner -->


    </div>
  </div>
`
    }
    ;
});