/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function () {
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="proposal">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner"> 
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="main-title sliding">
          <img src="/static/header_brand.png" />
        </div>
        <div class="right">
          <div class="avatar"><a href="/login/"><img src="/static/avatar-default.png"/></a></div>
          <!--<a class="white link external" href="tel:+15612508863">(561) 250-TUNE</a>-->
        </div>
      </div>
    </div>                     

    <!-- Scrollable page content-->
    <div id="proposal-5" class="page-content">


    <!-- Page Inner -->

      <div data-role="content" class="no-padding-content full-height">


        <div id="header-text" class="orange-underline row"><div class="header-wrap">
          <a href="#" class="link back">
            <i class="icon icon-back"></i>
            <span class="">Back</span>
          </a>
          <h3>Proposal Generator<div class="header-ui-updated-date crm-leads-updated-date"></div></h3></div></div>
        <div class="inner-wrapper content-wrapper">


          <div class="content-section loading-container proposal-content-section">


            <form id="crm-form">

              <span class="created"></span>
              <!--<span class="last-revised"></span>-->

              <div class="sales-notes-log">

                <span class="single-detail existing-website">Existing Website: </span>
                <span class="single-detail logo">Logo: </span>

                <span class="single-detail businessname">Brand: </span>
                <span class="single-detail domain">Domain: </span>
                <span class="single-detail sales-agent">Sales Agent: </span>
                <span class="single-detail fullname">Name: </span>
                <span class="single-detail phone">Phone: </span>
                <span class="single-detail email">Email: </span>

                <span class="single-detail project-scope-id">Project ID: </span>
                
                
                <span class="single-detail example-findings-desc"></span>
                <span class="single-detail example-recommendations-desc"></span>
                <span class="single-detail mockups-desc"></span>
                <span class="single-detail features-desc"></span>
                <span class="single-detail timeline-desc"></span>
                
                <span class="single-detail line-item-1"></span>
                <span class="single-detail line-item-2"></span>
                <span class="single-detail line-item-3"></span>
                <span class="single-detail line-item-4"></span>
                <span class="single-detail line-item-5"></span>
                
                <span class="single-detail line-item-1-cost"></span>
                <span class="single-detail line-item-2-cost"></span>
                <span class="single-detail line-item-3-cost"></span>
                <span class="single-detail line-item-4-cost"></span>
                <span class="single-detail line-item-5-cost"></span>

                <span class="single-detail payments-1"></span>
                <span class="single-detail payments-2"></span>
                <span class="single-detail payments-3"></span>


              </div>




<div class="accordion-list"><!-- Begin Accordion -->


  <div class="accordion-item">
    <div class="accordion-item-toggle">
              <h3 class="orange-underline-no-margin">Populate<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>

    </div>
    <div class="accordion-item-content">

              <select class="industry" name="industry">
                <option value="default">Select Industry</option>
                <option value="loading">Loading...</option>
                <!-- Populated from Taxonomy API -->
              </select>
    </div>
  </div>



  <div class="accordion-item">
    <div class="accordion-item-toggle">

              <h3 class="orange-underline-no-margin">Cover Page<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>

    </div>
    <div class="accordion-item-content">

              <label>Brand / Business Name</label>
              <input name="brand" class="form-control brand" placeholder="Brand / Business Name"/>

              <label>Domain Name</label>
              <input name="domain" class="form-control domainname" placeholder="Domain Name"/>
              <span class="tip">If none, type "TBD"</span>

              <label>Sales Agent</label>
              <input name="salesgent" class="form-control salesagent" placeholder="Sales Agent"/>

              <label>Objective</label>
              <textarea name="objective" class="form-control objective" placeholder="Objective"/>

              <label>Example Websites</label>
              <input name="example-domain-one" class="form-control example-domain-one" value="https://www.habatatgalleries.com/" placeholder="Example Website One"/>

              <input name="example-domain-two" class="form-control example-domain-two" value="https://www.sfsciencecenter.org/" placeholder="Example Website Two"/>

              <input name="example-domain-three" class="form-control example-domain-three" value="https://www.graphicwebdesign.com/" placeholder="Example Website Three"/>

    </div>
  </div>


  <div class="accordion-item">
    <div class="accordion-item-toggle">

              <h3 class="orange-underline-no-margin">Scope of Work<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
    </div>
    <div class="accordion-item-content">

              <label>Findings</label>
              <textarea name="findings" class="form-control findings" placeholder="Findings">There is currently a website in place.</textarea>

              <label>Recommendations</label>
              <textarea name="recommendations" class="form-control recommendations" placeholder="Recommendations">Design and develop a secure website with the given design specifications and features. Create a mobile and tablet friendly theme that will adapt to any size device.</textarea>

              <label>Web Pages</label>
              <textarea name="webpages" class="form-control webpages" placeholder="Web Pages">- Home Page\n- About Us\n- Services\n- Contact</textarea>

              <label>Features</label>
              <textarea name="features" class="form-control features" placeholder="Features">- Admin login to edit content and images\nContact form database storage\nOne hour of support and edit (with managed hosting)</textarea>

              <label>Timeline</label>
              <textarea name="timeline" class="form-control timeline">Design (2 to 4 weeks*)\nDevelopment (2 to 4 weeks)\n\n*Timelines greatly depend on prompt client feedback (i.e. photography, content, and logins)</textarea>

    </div>
  </div>

  <div class="accordion-item">
    <div class="accordion-item-toggle">

              <h3 class="orange-underline-no-margin">Cost Breakdown<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
    </div>
    <div class="accordion-item-content">

              <span class="tip">Omit the trailing double zero (.00) and dollar sign.</span>

              <div id="line-items">

                  <input name="line1" class="form-control line1 desc" value="Front End Design"/>

                  <input name="line1cost" class="align-items-right form-control line1cost cost" value="800"/>

                  <input name="line2" class="form-control line2 desc" value="Web Development"/>

                  <input name="line2cost" class="align-items-right form-control line2cost cost" value="1800"/>

                  <div class="line-items-expand">Add Line Items<i class="f7-icons icon material-icons color-white">plus_app_fill</i></div>

                  <input name="line3" class="form-control line3 desc hide expandable" value="" placeholder="Line Item 3" />

                  <input name="line3cost" class="align-items-right form-control line3cost cost hide expandable" value="0" placeholder="0"/>

                  <input name="line4" class="form-control line4 desc hide expandable" value="" placeholder="Line Item 4"/>

                  <input name="line4cost" class="align-items-right form-control line4cost cost hide expandable" value="0" placeholder="0"/>

                  <input name="line5" class="form-control line5 desc hide expandable" value="" placeholder="Line Item 5"/>

                  <input name="line5cost" class="align-items-right form-control line5cost cost hide expandable" value="0" placeholder="0"/>

                  <input name="line6" class="form-control line6 desc" value="Total"/>

                  <input name="line6cost" class="align-items-right form-control line6cost cost" value="2600"/>
                  <div class="align-items-right form-detail-left-margin"><i class="f7-icons icon material-icons color-white">money_dollar</i></div>

                  <input name="line7" class="form-control line7 desc" value="Secure Hosting and Maintenance"/>

                  <input name="line7cost" class="align-items-right form-control line7cost cost" value="60/mo"/>
                  <div class="align-items-right form-detail-left-margin"><i class="f7-icons icon material-icons color-white">money_dollar</i></div>

              </div>

        </div>
      </div>


  <div class="accordion-item">
    <div class="accordion-item-toggle">

              <h3 class="orange-underline-no-margin">Payments<i class="float-items-right f7-icons icon material-icons color-white plus">plus_circle_fill</i><i class="float-items-right f7-icons icon material-icons color-white minus hide">minus_circle_fill</i></h3>
    </div>
    <div class="accordion-item-content">


              <input name="payments1" class="form-control payments1 desc" value="Project Kickoff"/>

              
              <input name="payments1cost" class="align-items-right form-control payments1cost cost" value="1300"/>
              <div class="align-items-right form-detail-left-margin"><i class="f7-icons icon material-icons color-white">money_dollar</i></div>

              <input name="payments2" class="form-control payments2 desc" value="Design Approval"/>

              <input name="payments2cost" class="align-items-right form-control payments2cost cost" value="650"/>
              <div class="align-items-right form-detail-left-margin"><i class="f7-icons icon material-icons color-white">money_dollar</i></div>

              <input name="payments3" class="form-control payments3 desc" value="Launch to Production"/>

              <input name="payments3cost" class="align-items-right form-control payments3cost cost" value="650"/>
              <div class="align-items-right form-detail-left-margin"><i class="f7-icons icon material-icons color-white">money_dollar</i></div>


        </div>
      </div>

    </div><!-- End Accordion -->

              <div class="debug"></div>   

              <a class="generate-proposal">Generate Proposal</a>              

            </form>

          </div>

        </div>
      </div>


    <!-- End Page Inner -->


    </div>
  </div>
`
    }
    ;
});